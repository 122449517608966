import { createStore } from 'vuex'
import { Tool, TriggerSpeed } from '@/tools'

export default createStore({
  state: {
    timeline: null,
    currentTool: Tool.Pan,
    title: 'Untitled',
    duration: 300,
    cannonNumbers: [1, 2, 3],
    numTriggers: 0,
    toolbarHeight: 70, // height and margin of toolbar
    fireDurations: {
      fog: 500,
      reverse: 1500,
      settle: 3000,
      forward: 1000,
      idle: 2000
    },
    frameRate: 20,
    triggerSpeed: TriggerSpeed.Slow,
    undo: {
      stack: [],
      index: 0
    }
  },
  getters: {
    triggerDuration(state) {
      const { reverse, settle, forward, idle } = state.fireDurations
      return (reverse + settle + forward + idle) / 1000
    }
  },
  mutations: {
    setTool(state, tool) {
      state.currentTool = tool
    },
    deleteTrack(state, index) {
      const oldNumbers = [...state.cannonNumbers]
      state.cannonNumbers.splice(index, 1)
      state.timeline.reorder(oldNumbers, state.cannonNumbers)
    },
    addTrack(state, index) {
      const oldNumbers = [...state.cannonNumbers]
      const newCannonNumber = Math.max(...state.cannonNumbers) + 1
      state.cannonNumbers.splice(index + 1, 0, newCannonNumber)
      state.timeline.reorder(oldNumbers, state.cannonNumbers)
    },
    updateDuration(state, { name, value }) {
      state.fireDurations[name] = +value
      state.timeline.draw()
    },
    setTriggerSpeed(state, speed) {
      state.triggerSpeed = speed
    },
    reorderNumbers(state, numbers) {
      const oldNumbers = [...state.cannonNumbers]
      state.cannonNumbers = numbers
      state.timeline.reorder(oldNumbers, state.cannonNumbers)
    },
    setNumTriggers(state, n) {
      state.numTriggers = n
    },
    setDuration(state, duration) {
      state.duration = duration
      state.timeline.drawGrid()
    },
    resetState(state) {
      state.undo = { stack: [], index: 0 }
    },
    pushState(state) {
      const cannonNumbers = [...state.cannonNumbers]
      const fireDurations = Object.fromEntries(Object.entries(state.fireDurations))
      const triggers = state.timeline.triggers.map(trigger => trigger.clone())

      while (state.undo.index > 0) {
        state.undo.stack.shift()
        state.undo.index--
      }

      state.undo.stack.unshift({ cannonNumbers, fireDurations, triggers })
    },
    undo(state, direction) {
      const { undo } = state
      undo.index = Math.max(0, Math.min(undo.stack.length - 1, undo.index + direction))
      const { cannonNumbers, fireDurations, triggers } = undo.stack[undo.index]
      state.cannonNumbers = cannonNumbers
      state.fireDurations = fireDurations
      state.timeline.setTriggers(triggers)
    }
  },
  actions: {
    load({ state, commit }, json) {
      const { title, duration, fire_durations, tracks, triggers, fps } = json
      state.title = title
      state.duration = duration
      Object.assign(state.fireDurations, fire_durations)
      // backwards compatibility for when tracks was mislabeled as triggers
      state.cannonNumbers = (tracks || triggers).map(t => t.number)
      state.timeline.load((tracks || triggers).map(t => t.triggers))
      if (fps) {
        state.frameRate = fps
      }
      commit('pushState')
    },
    addTrack({ commit }, index) {
      commit('addTrack', index)
      commit('pushState')
    },
    deleteTrack({ commit }, index) {
      commit('deleteTrack', index)
      commit('pushState')
    },
    renameTrack({ commit, state }, { index, number }) {
      state.cannonNumbers[index] = number
      commit('pushState')
    },
    updateDuration({ commit }, duration) {
      commit('updateDuration', duration)
      commit('pushState')
    },
    reorderNumbers({ commit }, numbers) {
      commit('reorderNumbers', numbers)
      commit('pushState')
    }
  },
  modules: {
  }
})
